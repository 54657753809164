<template>
  <div>
    <div v-if="!verify">
      <div class="container">
        <div class="row flex-column row-cont">
          <h2 class="m-auto">Hediye seçmeden önce yapman gerekenler var 🐝</h2>
          <div class="security">
            <b-form-group>
              <div
                class="d-flex flex-wrap align-items-center justify-content-center my-1"
              >
                <label for="cvv" class="text-nowrap mr-1 mb-1">
                  Güvenlik için kodu giriniz:
                </label>
                <b-form-input
                  v-model="verifyCode"
                  class="mr-1 mb-1"
                  size="lg"
                  trim
                />
                <b-button variant="primary" class="mb-1" @click="getGifts()">
                  Onayla
                </b-button>
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <div class="gift-container" v-if="!finish && verify && !twoStep">
      <div v-if="media" class="welcome">
        <h1 class="text-primary mb-2">
          Sana bir mesaj var; <span class="text-body">{{ fullName }}</span
          >! hadi yine iyisin ;)
        </h1>
        <component  class="mb-2 m-auto" :is="mediaType" :src="media" controls autoplay muted height="500px" width="800px"/>
        <div class="d-flex mt-lg-4 m-auto">
          <b-button variant="primary" class="mb-1" @click="media = ''">
            İlerle
          </b-button>
        </div>
      </div>
      <div v-else>
        <div class="welcome">
          <h1 class="text-primary mb-2">
            Hoşgeldin <span class="text-body">{{ fullName }}</span
            >, bugün şanslı günün ;)
          </h1>
          <h3 class="mb-2">
            Senin için bunları hazırladık hadi hediyelerini seç ve ilerle
          </h3>
          <p>Seçimini yapman için; {{ timerOutput }}</p>
          <h3 class="text-danger mt-2 w-50 text-center" v-if="reSelect">
            Seni daha önce buralarda görmüştük, seçimini yaptın aşağıdan ürünü
            görüntüleyebilirsin
          </h3>
        </div>
        <section :class="itemView">
          <b-card
            v-for="product in products"
            :key="product.id"
            :class="`ecommerce-card ${
              product.id === selectedProductId ? 'selected' : ''
            }`"
            no-body
            @click="selectGift(product)"
          >
            <div class="item-img text-center">
              <b-carousel
                id="carousel-interval"
                :interval="50000000"
                controls
                indicators
              >
                <b-carousel-slide
                  v-for="(p, index) in product.images"
                  :key="index"
                  :img-src="p"
                />
              </b-carousel>
            </div>

            <!-- Product Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div></div>
              </div>
              <h6 class="item-name">
                <b-link class="text-body">
                  {{ product.name }}
                </b-link>
                <b-card-text class="item-company">
                  By
                  <b-link class="ml-25">
                    {{ product.brand }}
                  </b-link>
                </b-card-text>
              </h6>
              <b-card-text class="item-description">
                {{ product.title }}
              </b-card-text>
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center position">
              <div class="item-wrapper">
                <div class="item-cost">
                  <h4 class="item-price">
                    <b-button
                      variant="outline-primary"
                      class="btn-lg"
                      @click.prevent.stop="goToDetail(product)"
                    >
                      <span>Ürün Detayı</span>
                    </b-button>
                  </h4>
                </div>
              </div>
              <div class="mb-2" />
            </div>
          </b-card>
        </section>
        <div class="d-flex justify-content-center" v-if="!reSelect">
          <b-button
            variant="primary"
            class="btn-lg"
            @click="nextStep"
            :disabled="!selectedProductId"
          >
            <feather-icon icon="Arrow" class="mr-50" />
            <span>İlerle</span>
          </b-button>
        </div>
      </div>
    </div>
    <div v-if="!finish && twoStep && verify">
      <div class="container">
        <div class="row flex-column row-cont">
          <h2 class="m-auto">Süpersin ve son adımdasın! 🎁</h2>
          <div class="security">
            <b-form-group>
              <div
                class="d-flex flex-wrap align-items-center justify-content-center my-1"
              >
                <label for="İl" class="text-nowrap mr-1 mb-1">
                  Telefon Numarası giriniz:
                </label>
                <b-form-input v-model="phoneNumber" class="mr-1 mb-1" trim />
                <label for="İl" class="text-nowrap mr-1 mb-1">
                  İl giriniz:
                </label>
                <b-form-select
                  v-model="province"
                  place-holder="Seçiniz"
                  class="mb-1"
                  :options="citys"
                />
                <label for="İlçe" class="text-nowrap mr-1 mb-1">
                  İlçe giriniz:
                </label>
                <b-form-select
                  :disabled="!counties"
                  v-model="county"
                  :options="counties"
                  class="mb-1"
                />
                <label for="cvv" class="text-nowrap mr-1 mb-1">
                  Detaylı adres giriniz
                </label>
                <b-form-textarea
                  v-model="address"
                  class="mb-1"
                  size="lg"
                  trim
                />
                <b-button
                  variant="primary"
                  class="mb-1"
                  :disabled="!county || !province || !address"
                  @click="finishStep()"
                >
                  İlerle
                </b-button>
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <div v-if="finish && verify">
      <Finish :fullName="fullName" />
    </div>
  </div>
</template>
<script>
import {
  BFormTextarea,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BCarousel,
  BCarouselSlide,
  BFormCheckbox,
} from "bootstrap-vue";
import Finish from "./Finish";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import turkey from "./turkey";

export default {
  components: {
    // BSV
    BFormSelect,
    BFormTextarea,
    BCarousel,
    BFormCheckbox,
    BCarouselSlide,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    Finish,
    ToastificationContent,
  },
  data() {
    return {
      products: [],
      itemView: "grid-view",
      endDate: new Date(),
      timerOutput: "",
      selectedProductId: "",
      finish: false,
      reSelect: false,
      fullName: "",
      giftId: "",
      verify: false,
      verifyCode: "",
      twoStep: false,
      address: "",
      province: "",
      county: "",
      phoneNumber: "",
      media: "",
    };
  },
  computed: {
    citys() {
      return turkey.map((item) => {
        return item.text;
      });
    },
    counties() {
      return this.province !== ""
        ? turkey
            .filter((item) => item.text === this.province)
            .flatMap((x) => x.districts.map((y) => y.text))
        : false;
    },
    mediaType() {
      if (this.media.endsWith("mp4")) return "video";
      if (this.media.endsWith("mp3")) return "audio";
      else return "img";
    },
  },
  methods: {
    getGifts() {
      this.getProducts();
    },
    startTimer: function () {
      const timeNow = new Date().getTime();
      const timeDifference = this.endDate - timeNow;
      const millisecondsInOneSecond = 1000;
      const millisecondsInOneMinute = millisecondsInOneSecond * 60;
      const millisecondsInOneHour = millisecondsInOneMinute * 60;
      const millisecondsInOneDay = millisecondsInOneHour * 24;
      const differenceInDays = timeDifference / millisecondsInOneDay;
      const remainderDifferenceInHours =
        (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
      const remainderDifferenceInMinutes =
        (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
      const remainderDifferenceInSeconds =
        (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
      const remainingDays = Math.floor(differenceInDays);
      const remainingHours = Math.floor(remainderDifferenceInHours);
      const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
      const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
      this.timerOutput =
        remainingDays +
        " gün " +
        remainingHours +
        " saat " +
        remainingMinutes +
        " dakika " +
        remainingSeconds +
        " saniye kaldı";
    },
    goToDetail(product) {
      this.$router.push({
        name: "public-product-details",
        params: { slug: product.id },
      });
    },
    getProducts() {
      let pathName = `?token=${this.$route.query.token}&email=${this.$route.query.email}&code=${this.verifyCode}`;
      this.$http
        .get("/api/v1/gift-package" + pathName)
        .then((res) => {
          this.verify = true;
          this.giftId = res.data.data.id;
          this.products = res.data.data.availableProducts;
          this.desc = res.data.data.description;
          this.endDate = new Date(res.data.data.endDate).getTime();
          this.fullName = res.data.data.name + " " + res.data.data.surname;
          this.selectedProductId = res.data.data.selectedProductId;
          this.media = res.data.data.media[0];
          if (this.selectedProductId) this.reSelect = true;
          setInterval(() => {
            this.startTimer();
          }, 1000);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Güvenlik kodunu yanlış girdiniz",
              icon: "EditIcon",
              variant: "warning",
            },
          });
        });
    },
    selectGift(product) {
      this.selectedProductId = product.id;
    },
    nextStep() {
      this.twoStep = true;
    },
    finishStep() {
      this.$http
        .put("/api/v1/gift-package/" + this.giftId, {
          selectedProductId: this.selectedProductId,
          token: this.$route.query.token,
          email: this.$route.query.email,
          code: this.verifyCode,
          address: this.address,
          province: this.province,
          county: this.county,
          phoneNumber: this.phoneNumber,
        })
        .then((res) => {
          this.finish = true;
        });
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.checkboxforproduct {
  position: absolute !important;
  top: -5px;
  left: -8px;
  z-index: 99999;
}
.grid-view {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  & > div {
    margin-right: 20px;
  }
}
.gift-container {
  margin-top: 60px;
  & > .welcome {
    display: flex;
    flex-direction: column;
    & > h1,
    h3,
    p {
      margin: auto;
    }
  }
  & > div > .welcome {
    display: flex;
    flex-direction: column;
    & > h1,
    h3,
    p {
      margin: auto;
    }
  }
}
.ecommerce-card {
  cursor: pointer;
  border: 2px solid transparent;
}
.selected {
  box-shadow: 0 1px 1px 0 #e29134, 0 1px 20px 0 #e29134;
}
.security {
  max-width: 400px;
  margin: 20px auto;
}
.row-cont {
  margin-top: 100px;
}
</style>