<template>
  <b-card
    text-variant="center"
    class="card card-congratulations p-5 finish-card"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->

    <b-avatar
      variant="primary"
      size="70"
      class="shadow mb-2"
    >
      <feather-icon
        size="28"
        icon="AwardIcon"
      />
    </b-avatar>
    <h1 class="mb-1 mt-50 text-white">
      Tebrikler {{ fullName }}
    </h1>
    <b-card-text class="m-auto w-75">
      Hediyeni başarıyla seçtin, kargon yola çıkıyor :)
    </b-card-text>
    <b-link to="/" class="text-white">Anasayfaya dön</b-link>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText, BLink
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    BLink
  },
  props: {
    fullName: '',
  },
  data(){
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  }
}
</script>

<style lang="scss" scoped>
.finish-card {
    max-width: 900px;
    margin: auto;
    margin-top: 200px;
}
</style>
